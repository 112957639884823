import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import createRootReducer from '../reducers'

const createBrowserHistory = require('history').createBrowserHistory;
// Function to redirect to non-www URL if necessary
const normalizeUrl = () => {
  if (window.location.hostname.startsWith('www.')) {
    const newUrl = window.location.protocol + '//' + window.location.hostname.replace('www.', '') + window.location.pathname + window.location.search;
    window.location.replace(newUrl);
  }
};

normalizeUrl();
export const history = createBrowserHistory({ basename: '/' });
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];

export default function configureStore(preloadedState = {}) {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );
}
