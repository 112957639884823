import moment from 'moment';
import { httpClient } from "../../util/Api";
import Swal from "sweetalert2";
import { Children } from 'react';

export const randomNumberUsers = () => {
  const min = 2;
  const max = 80;
  // Generar un número aleatorio en el rango especificado
  const numeroAleatorio = Math.floor(Math.random() * (max - min + 1)) + min;

  return numeroAleatorio;
};


export const showLoadingModal = () => {
  Swal.fire({
    title: 'Cargando...',
    text: 'Por favor, espera un momento..',
    icon: 'info',
    allowOutsideClick: false,
    allowEscapeKey: false,
    focusConfirm: false,
    showConfirmButton: false, // Ocultar el botón de confirmación
    showCloseButton: false,
    didOpen: () => {
      Swal.showLoading(); // Mostrar el ícono de carga
    }
  });
};

export const hideLoadingModal = () => {
  Swal.close(); // Cierra el modal de carga de SweetAlert2
};

export const generateToken = async () => {
  const token = localStorage.getItem("token");
  await httpClient
    .get(`login?loginName=summer.portal&password=summer.portal&output=json`, {
      mode: "no-cors",
    })
    .then(({ data }) => {
      if (data.status === "ok") {
        localStorage.setItem("token", data.sessionId);
      }
    })
    .catch(function (error) {
      console.log('Error: ', error);
    });
};

export const validToken = async () => {
  // Obtener el valor almacenado en localStorage para la clave "token"
  const token = localStorage.getItem("token");
  try {
    if (!token) {
      await generateToken();
      return true;
    } else {
      return false;
    }
    // Realizar acciones con la URL generada...
  } catch (error) {
    console.error('Error al obtener la Token:', error);
    return false;
  }
}

export const getCitys = async () => {
  const token = localStorage.getItem("token");
  try {
    let query = encodeURI(
      `SELECT id,name,lat,lng FROM Ciudad1 WHERE Disponible=true`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=999999&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          value: value[0],
          label: value[1],
          lat: parseFloat(value[2]),
          lng: parseFloat(value[3]),
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getCitysById = async (idCity) => {
  const token = localStorage.getItem("token");
  try {
    let query = encodeURI(
      `SELECT id,name,lat,lng FROM Ciudad1 WHERE id = ${idCity} `
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=1&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = {
        value: data[0][0],
        label: data[0][1],
        lat: parseFloat(data[0][2]),
        lng: parseFloat(data[0][3]),
      };
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getExperiences = async (codeTypeExp) => {
  const token = localStorage.getItem("token");
  try {
    var query = '';
    if(codeTypeExp){
      query= encodeURI(
        `SELECT id,Tipo_Experiencia,Nombre,Check_In,Check_Out,Costo_por_nios,Desayunos,Url_Imagen,Descripcin_1 FROM Experiencias WHERE Tipo_Experiencia = "${codeTypeExp}" `
      );
    }else{
      query= encodeURI(
        `SELECT id,Tipo_Experiencia,Nombre,Check_In,Check_Out,Costo_por_nios,Desayunos,Url_Imagen,Descripcin_1 FROM Experiencias`
      );
    }
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=99999&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
        id: value[0],
        codeType: value[1],
        name: value[2],
        checkIn: value[3],
        checkOut: value[4],
        childrens: value[5],
        breakfast: value[6],
        urlImg: value[7],
        desc1: value[8],
      };
    });
    return final_data;
  } else {
    return [];
  }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getPropertys = async (idLocation, cantGuest, arrDates, isOutstanding) => {
  const token = localStorage.getItem("token");
  try {
    let existLocation = false;
    let existCantGuest = false;
    let existArrDates = false;
    if (idLocation) { 
      existLocation = true; localStorage.setItem("idLocation", idLocation);}
    else{
      localStorage.removeItem("idLocation");
    }
    if (cantGuest) { 
      existCantGuest = true; localStorage.setItem("cantGuest", cantGuest); 
    }else{
      localStorage.removeItem("cantGuest");
    }
    if (arrDates) { 
      existArrDates = true; localStorage.setItem("arrDates", arrDates); 
    }else{
      localStorage.removeItem("arrDates");
    }
    var query = '';
    if (isOutstanding) {
      query = encodeURI(
        `SELECT R54120838,Titulo,Descripcion,Estado_Inmueble,Imagen_Principal,Ciudad_txt,Precio_Estandar,Num_Habitaciones,Num_Huespedes,Num_Bathrooms,Desayuno,Almuerzo,Estrellas,Latitud,Longitud,COUNT(*),Tot_Reviews FROM Disponibilidad3 WHERE Destacado = true AND Estado_Inmueble = 11419540 GROUP BY R54120838,Titulo,Descripcion,Estado_Inmueble,Imagen_Principal,Ciudad_txt,Precio_Estandar,Num_Habitaciones,Num_Huespedes,Num_Bathrooms,Desayuno,Almuerzo,Estrellas,Latitud,Longitud,Tot_Reviews ORDER BY Precio_Estandar DESC`
      );
    } else {
      query = encodeURI(
        `SELECT R54120838,Titulo,Descripcion,Estado_Inmueble,Imagen_Principal,Ciudad_txt,Precio_Estandar,Num_Habitaciones,Num_Huespedes,Num_Bathrooms,Desayuno,Almuerzo,Estrellas,Latitud,Longitud,COUNT(*),Tot_Reviews FROM Disponibilidad3 WHERE Disponible=true AND Estado_Inmueble = 11419540 ${idLocation ? 'AND R54136449 = ' + idLocation : ''} ${cantGuest ? 'AND Num_Huespedes >= ' + cantGuest : ''} ${arrDates ? stringRangeDates(arrDates) : ''} GROUP BY R54120838,Titulo,Descripcion,Estado_Inmueble,Imagen_Principal,Ciudad_txt,Precio_Estandar,Num_Habitaciones,Num_Huespedes,Num_Bathrooms,Desayuno,Almuerzo,Estrellas,Tot_Reviews,Latitud,Longitud ${arrDates ? `HAVING COUNT(*) >= ${differenceDays(arrDates)} ` : ''}ORDER BY Precio_Estandar DESC`
      );
    }
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=999999&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          idProperty: value[0],
          title: value[1],
          description: value[2],
          property_state: value[3],
          main_image: value[4],
          city: value[5],
          price: value[6],
          numberRooms: value[7],
          numberGuests: value[8],
          numberBathrooms: value[9],
          breakfast: value[10],
          lunch: value[11],
          stars: value[12],
          lat: parseFloat(value[13]),
          lng: parseFloat(value[14]),
          availabilityDays: value[15],
          totReviews:value[16]
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getPropertyById = async (idProperty) => {
  const token = localStorage.getItem("token");
  try {
    let query = encodeURIComponent(`SELECT Descripcion,Estrellas,Tipo_Inmueble#value,Numero_Huesped,Numero_Habitaciones,Numero_Banos,Camas,Descripcin_2,Mascotas,TV,Aire_Acondicionado,Zona_BBQ,Estacionamiento_Gratis,Vista_a_la_Playa,Wifi,Lavadora,Piscina,Secadora_de_pelo,Toallas___Sabanas,Precio_T_Alta,Hora_Entrada,Descripcin_3,Latitud,Longitud,Titulo_Promocin,Descripcin_Promocin,Valor_Aseo,Plan#code FROM Inmueble WHERE id = ${idProperty}`);
    const { data } = await httpClient.get(`/selectQuery?maxRows=1&query=${query}&sessionId=${token}&output=json`);
    if (data.length > 0) {
      const final_data = {
        title: data[0][0],
        stars: data[0][1],
        type: data[0][2],
        numberGuests: data[0][3],
        numberRooms: data[0][4],
        numberBathrooms: data[0][5],
        numberBed: data[0][6],
        desc: data[0][7],
        pets: data[0][8],
        tv: data[0][9],
        airCond: data[0][10],
        zoneBbq: data[0][11],
        parkingFree: data[0][12],
        beachView: data[0][13],
        wifi: data[0][14],
        washingMac: data[0][15],
        pool: data[0][16],
        dryer: data[0][17],
        towels: data[0][18],
        price: data[0][19],
        income: data[0][20],
        rules: data[0][21],
        lat: parseFloat(data[0][22]),
        lng: parseFloat(data[0][23]),
        promTitle: data[0][24],
        promDesc: data[0][25],
        clearPrice: parseFloat(data[0][26]) || 0,
        codePlan: data[0][27],
        idProperty: idProperty,
      };
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getTypeExperiences = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await httpClient.get(
      `getPicklist?objName=Experiencias&fieldName=Tipo_Experiencia&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => ({
        value: value.code,
        label: value.name,
        code: value.code,
      }));
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getExperienceById = async (idExp) => {
  const token = localStorage.getItem("token");
  try {
    let query = encodeURIComponent(`SELECT id,Tipo_Experiencia,Nombre,Check_In,Check_Out,Costo_por_nios,Desayunos,Url_Imagen,Descripcin_1,Descripcin_2,Descripcin_3 FROM Experiencias WHERE id = ${idExp}`);
    const { data } = await httpClient.get(`/selectQuery?maxRows=1&query=${query}&sessionId=${token}&output=json`);
    if (data.length > 0) {
      const final_data = {
        id: data[0][0],
        codeType: data[0][1],
        name: data[0][2],
        checkIn: data[0][3],
        checkOut: data[0][4],
        childrens: data[0][5],
        breakfast: data[0][6],
        urlImg: data[0][7],
        desc1: data[0][8],
        desc2: data[0][9],
        desc3: data[0][10],
      };
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};


export const getPhotosPropertyById = async (idProperty) => {
  const token = localStorage.getItem("token");
  try {

    let query = encodeURI(
      `SELECT id,Url FROM Foto WHERE R53997035 = ${idProperty}`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=100&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          url: value[1],
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getPhotosExperiencesById = async (id) => {
  const token = localStorage.getItem("token");
  try {

    let query = encodeURI(
      `SELECT id,Url FROM Foto WHERE R56567201 = ${id}`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=100&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          url: value[1],
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getReviewsPropertyById = async (idProperty) => {
  const token = localStorage.getItem("token");
  try {

    let query = encodeURI(
      `SELECT id,Nombre_Cliente,Comentario,Calificacion FROM Review WHERE R54288516 = ${idProperty}`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=1000000&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          id: value[0],
          nameClient: value[1],
          coment: value[2],
          stars: value[3],
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getReviewsTestimonials = async () => {
  const token = localStorage.getItem("token");
  try {

    let query = encodeURI(
      `SELECT id,Nombre_Cliente,Comentario,Calificacion,R54288516,Titulo,Descripcion,Estado_Inmueble,Imagen_Principal,Ciudad_txt,Precio_Estandar,Num_Habitaciones,Num_Huespedes,Num_Bathrooms,Desayuno,Almuerzo,Estrellas,Latitud,Longitud FROM Review WHERE Calificacion > 3 ORDER BY RAND()`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=15&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          id: value[0],
          nameClient: value[1],
          coment: value[2],
          stars: value[3],
          idProperty: value[4],
          title: value[5],
          description: value[6],
          property_state: value[7],
          main_image: value[8],
          city: value[9],
          price: value[10],
          numberRooms: value[11],
          numberGuests: value[12],
          numberBathrooms: value[13],
          breakfast: value[14],
          lunch: value[15],
          stars: value[16],
          lat: parseFloat(value[17]),
          lng: parseFloat(value[18]),
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getParamsGlobal = async () => {
  const token = localStorage.getItem("token");
  try {

    let query = encodeURI(
      `SELECT Politicas_Empresa FROM Parametro1`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=1000000&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = {
        politic: data[0][0],
      };
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const getAvailabilityProperty = async (idProperty, arrDates) => {
  const token = localStorage.getItem("token");
  try {
    var existArrDates = false;
    if (arrDates) { existArrDates = true; }
    let query = encodeURI(
      `SELECT GROUP_CONCAT(id SEPARATOR '|'),Precio,COUNT(*) FROM Disponibilidad3 WHERE Disponible=true AND R54120838=${idProperty} ${arrDates ? stringRangeDates(arrDates) : ''} GROUP BY Precio ORDER BY Precio DESC`
    );
    const { data } = await httpClient.get(
      `/selectQuery?maxRows=999999&query=${query}&sessionId=${token}&output=json`
    );
    if (data.length > 0) {
      const final_data = data.map((value) => {
        return {
          idsAvailability:value[0],
          price: value[1],
          cantDays: value[2],
        };
      });
      return final_data;
    } else {
      return [];
    }
  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

/*
export const createPreference = async () => {
  try {
    const response = await httpClient.post(`http://localhost:4000/create_preference`, {
      title: 'Banana Contenta', quantity: 1,
      price: 3000
    });
    const { id } = response?.data;
    return id;
  } catch (error) {
    console.log(error);
  }
};
*/

export const createBooking = async (values, idClient,idsAvailability) => {

  const token = localStorage.getItem("token");
  try {
    const { data } = await httpClient.get(
      `/create2?output=json&useIds=true&objName=Reservas&R36525605=${values?.idProperty}&R54476616=${idClient}&Fecha_Inicio=${moment(values?.IncomeExit[0]).startOf('day').format('YYYY-MM-DD')}&Fecha_Fin=${moment(values?.IncomeExit[1]).startOf('day').format('YYYY-MM-DD')}&Cantidad_Huespedes=${values?.persons}&id_Transaccion=${values?.idTransaction}&Valor=${values?.total}&R54868421=${idsAvailability}&sessionId=${token}&output=json`
    );
    if (data) {
      return data;
    } else {
      return null;
    }

  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const createContact = async (values, isPublish) => {
  const token = localStorage.getItem("token");
  try {
    let query = '';
    if (!isPublish) {
      query = `/create2?output=json&useIds=true&objName=Solicitud_Contacto&Nombre_Completo=${values?.name}&email=${values?.email}&Telefono=${values?.tel}&Mensaje=${values?.message}&sessionId=${token}&output=json`
    } else {
      query = `/create2?output=json&useIds=true&objName=Solicitud_Contacto&Nombre_Completo=${values?.name}&email=${values?.email}&Telefono=${values?.tel}&Mensaje=${values?.message}&tenantType=${values?.tenantType}&typeProperty=${values?.typeProperty}&isResponsible=${values?.isResponsible}&Ciudad=${values?.cityProperty}&sessionId=${token}&output=json`
    }
    const { data } = await httpClient.get(query);
    if (data) {
      return data;
    } else {
      return null;
    }

  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const createClient = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await httpClient.get(
      `/create2?output=json&useIds=true&objName=Cliente1&Nombre=${values?.name}&Email=${values?.email}&Celular=${values?.cel}&sessionId=${token}&output=json`
    );
    if (data) {
      return data;
    } else {
      return null;
    }

  } catch (error) {
    httpClient.defaults.headers.common["Authorization"] = "";
    return Promise.reject(error);
  }
};

export const createPreference = async (itemsArr) => {
  try {
    const response = await fetch('https://api.mercadopago.com/checkout/preferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer TEST-5295888004731951-090111-6e851ac1c700c802b3d17753ed1968aa-456054032' // Reemplaza YOUR_ACCESS_TOKEN con tu token de acceso
      },
      body: JSON.stringify({
        items: itemsArr,
        back_urls: {
          //success: 'https://www.impeltechnology.com/summer4rent/transaction-successful',
          //failure: 'https://www.impeltechnology.com/summer4rent/transaction-waiting',
          //pending: 'https://www.impeltechnology.com/summer4rent/transaction-rejected',
          success: 'https://www.summer4rent.com/transaction-successful',
          failure: 'https://www.summer4rent.com/transaction-waiting',
          pending: 'https://www.summer4rent.com/transaction-rejected',
        },
        auto_return: 'approved'
      }),
    });
    const data = await response.json();
    return data; // Devuelve el ID de la preferencia
  } catch (error) {
    console.error('Error al crear la preferencia:', error);
    throw error; // Maneja el error según sea necesario
  }
};


function stringRangeDates(arrDates) {
  if (Array.isArray(arrDates)) {
    const fechaIni = moment(arrDates[0]).startOf('day').format('YYYY-MM-DD');
    const fechaFin = moment(arrDates[1]).startOf('day').format('YYYY-MM-DD');
    return `AND Fecha >= '${fechaIni}' AND Fecha <='${fechaFin}' `;
  } else {
    const aux = arrDates.split(',');
    const fechaIni = moment(aux[0]).startOf('day').format('YYYY-MM-DD');
    const fechaFin = moment(aux[1]).startOf('day').format('YYYY-MM-DD');
    return `AND Fecha >= '${fechaIni}' AND Fecha <='${fechaFin}' `;
  }

}

function differenceDays(arrDates) {
  if (Array.isArray(arrDates)) {
    const fechaIni = moment(arrDates[0]).startOf('day');
    const fechaFin = moment(arrDates[1]).endOf('day');
    return (fechaFin.diff(fechaIni, 'days'));
  } else {
    const aux = arrDates.split(',');
    const fechaIni = moment(aux[0]).startOf('day');
    const fechaFin = moment(aux[1]).endOf('day');
    return (fechaFin.diff(fechaIni, 'days'));
  }

}