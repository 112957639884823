import { useEffect, useState } from "react";
import { httpClient } from "../../../util/Api";
import Swal from "sweetalert2";
import "../../../styles/global/customGlobal.less"

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const getUrl = () => {
    return new Promise((resolve, reject) => {
      fetchStart();
      httpClient
        .get(`login?loginName=summer.portal&password=summer.portal&output=json`, {
          mode: "no-cors",
        })
        .then(async ({ data }) => {
          if (data.status === "ok") {
            fetchSuccess();
            localStorage.setItem("token", data.sessionId);
            const resp = await getUrl2(data.sessionId);
            console.log(resp);
            resolve(resp);
          } else {
            reject(data.error);
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error Interno...Rollbase no entrega token de acceso',
            customClass: {
              popup: 'ant-modal-login', // Clase CSS para el contenedor del modal
              htmlContainer: 'ant-modal-login', // Clase CSS para el contenedor del HTML del modal
            },
          });
        });
    });
  };

  const getUrl2 = async (token) => {
    try {
      let query = encodeURI(
        `SELECT tenantId,clientId,Client_Secret_Value,redirectUri FROM Parametro`
      );
      const { data } = await httpClient.get(
        `/selectQuery?maxRows=1&query=${query}&sessionId=${token}&output=json`
      );
      if (data.length > 0) {
        const values = data[0];
        return {
          tenantId: values[0],
          clientId: values[1],
          Client_Secret_Value: values[2],
          redirectUri: values[3],
        };
      } else {
        return {};
      }
    } catch (error) {
      httpClient.defaults.headers.common["Authorization"] = "";
      return Promise.reject(error);
    }
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    // setAuthUser({});
    httpClient
      .get(`login?loginName=acertar.portal&password=acertar.portal&output=json`, {
        mode: "no-cors",
      })
      .then(({ data }) => {
        if (data.status === "ok") {
          fetchSuccess();
          localStorage.setItem("token", data.sessionId);
          getAuthUser(user);
          if (callbackFun) callbackFun();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error Interno...Rollbase no entrega token de acceso',
          });
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post("auth/register", user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          // localStorage.setItem("token", data.token.access_token);
          httpClient.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Usuario no encontrado',
            text: 'El Usuario no existe,valide el usuario y la contraseña!',
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: 'error',
          title: 'Usuario no encontrado',
          text: 'El Usuario no existe,valide el usuario y la contraseña!',
        });
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    httpClient
      .post("auth/logout")
      .then(({ data }) => {
        if (data.result) {
          httpClient.defaults.headers.common["Authorization"] = "";
          setAuthUser(false);
          fetchSuccess();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAuthUser = (user) => {
    const token = localStorage.getItem("token");
    let query = encodeURI(
      `SELECT id,name,R15756004,Rol,status FROM Contactos WHERE User = '${user.email}' AND PasswordC  = '${user.password}'`
    );
    httpClient
      .get(
        `/selectQuery?maxRows=1&query=${query}&sessionId=${token}&output=json`
      )
      .then(({ data }) => {
        if (data.length > 0) {
          const values = data[0];
          console.log(values[4]);
          if (values[4] !== 15953444) { // Activo = 15953444
            Swal.fire({
              icon: 'error',
              title: 'Usuario Inactivo',
              text: `El Usuario ${values[1]} no se encuentra activo.`,
            });
            return;
          } else {
            setAuthUser({ idClient: values[2], name: values[1], id: values[0] });
            localStorage.setItem("name", values[1]);
            localStorage.setItem("idClient", values[2]);
            localStorage.setItem("idRol", values[3]);
            return;
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Usuario no encontrado',
            text: 'El Usuario no existe, valide el usuario y la contraseña!',
          });
          return;
        }
      })
      .catch(function (error) {
        httpClient.defaults.headers.common["Authorization"] = "";
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      httpClient.defaults.headers.common["Authorization"] = "";
    }
    setLoadingUser(false);
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
    getUrl,
    getUrl2,
  };
};
